import React from 'react'
import styled from 'styled-components';
import { Bounce } from 'react-awesome-reveal';
import ReactIcon from '../../images/Icons/react.png';
import JavascriptIcon from '../../images/Icons/javascript.png';
import CssIcon from '../../images/Icons/css3.png';
import SassIcon from '../../images/Icons/sass.png';
import FirebaseIcon from '../../images/Icons/firebase.png';
import HtmlIcon from '../../images/Icons/html5.png';
import MysqlIcon from '../../images/Icons/mysql.png';
import PythonIcon from '../../images/Icons/python.png';
import MongodbIcon from '../../images/Icons/mongodb.png';

const Skills = () => {





    return (
        <>
            <SkillsHolder id='skills'>

                <TopHeaderWrapper>
                    <Bounce direction="down" triggerOnce>
                        <h1>Skills</h1>
                        <span>Technologies and Tools</span>
                    </Bounce>
                </TopHeaderWrapper>


                <SkillsWrapper>
                    <Bounce direction="left" triggerOnce>
                        <SkillItem><Skill>
                            <Icon style={{ backgroundImage: `url(${ReactIcon})` }} />
                            <Icon style={{ backgroundImage: `url(${JavascriptIcon})` }} />
                            <Icon style={{ backgroundImage: `url(${HtmlIcon})` }} />
                        </Skill>
                            <h3>Web Application Development</h3>
                            <p>Worked with range of web technologies including HTML5,
                                Javascript. For most of my projects i used React.JS framework. I love to work with React, mostly because of the functional state management.</p>
                        </SkillItem>
                    </Bounce>
                    <Bounce direction="down" triggerOnce>
                        <SkillItem><Skill>

                            <Icon style={{ backgroundImage: `url(${PythonIcon})` }} />

                        </Skill>
                            <h3>Backend</h3>
                            <p>In the past few months i started to learn Python to have some experience and understanding of backend programming languages.</p>
                        </SkillItem>
                    </Bounce>
                    <Bounce direction="up" triggerOnce>
                        <SkillItem><Skill>
                            <Icon style={{ backgroundImage: `url(${FirebaseIcon})` }} />
                            <Icon style={{ backgroundImage: `url(${MysqlIcon})` }} />
                            <Icon style={{ backgroundImage: `url(${MongodbIcon})` }} />
                        </Skill>
                            <h3>Database</h3>
                            <p>I did use Google firebase NoSQL in many of my projects.I did use MySQL, SQLite and Mongoose with React and Python projects.</p>
                        </SkillItem>
                    </Bounce>
                    <Bounce direction="right" triggerOnce>
                        <SkillItem><Skill>
                            <Icon style={{ backgroundImage: `url(${SassIcon})` }} />
                            <Icon style={{ backgroundImage: `url(${CssIcon})` }} />
                        </Skill>
                            <h3>Responsive design</h3>
                            <p>Web pages must be rendered well on a variety of devices and window or screen sizes. Creating responsive web design is very important part of web development. I have an eye for detail and always aiming to create responsive elegant looking design.</p>
                        </SkillItem>
                    </Bounce>
                </SkillsWrapper>
            </SkillsHolder>
        </>
    )
}

export default Skills



const SkillsHolder = styled.div`
width:100vw;
min-height:500px;
display:flex;
flex-direction: column;
align-items: center;



@media screen and (max-width:768px){
    min-height: 300px;
   margin: 50px 0;
}
`;


const TopHeaderWrapper = styled.div`
display: flex;
flex-direction: column;
padding:0 15%;
width:100vw;
height:100px;


@media screen and (max-width:1300px){
    padding: 0 5%;
    margin:50px 0;
}
@media screen and (max-width:768px){
    align-items: center;
    width:90vw;
    margin:0;
    padding:0;
}

span{
    color:#ADADA3;
}
`;

const SkillsWrapper = styled.div`

display:flex;
width:100vw;
padding: 0 15%;
min-height:300px;
flex-wrap: wrap;
justify-content: space-between;

@media screen and (max-width:1300px){
    
    margin: 0 0 50px;
}

@media screen and (max-width:850px){
    flex-direction: column;
    align-items: center;
   
   padding: 0;
   justify-content: center;
}
`;

const SkillItem = styled.div`
width:300px;
height:100%;
display:flex;
flex-direction: column;
p{
    color: #ADADA3;
}

@media screen and (max-width:850px){
    width:90vw;
}
`

const Skill = styled.div`
width: 100%;
height:20%;
margin-bottom: 20px;
display:flex;
align-items: center;
justify-content: flex-start;

@media screen and (max-width: 768px){
    justify-content: center;
}

`;

const Icon = styled.div`
width:50px;
height:50px;
margin-right: 10px;
background-position: center;
object-fit: cover;

background-size: cover;
`;