import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { FaBars } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import cvPdf from '../../images/cv.pdf';

const Navbar = ({ isOpen, toggle }) => {




    const [scrollNav, setScrollNav] = useState(false);
    const [colorChange, setColorChange] = useState('false');

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
            setColorChange('true')
        } else {
            setScrollNav(false);
            setColorChange('false')
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop();
    }


    return (
        <>

            <IconContext.Provider value={{ color: '#fff' }}>
                <Nav scrollNav={scrollNav}  >
                    <NavbarContainer>
                        <NavLogo to='/' onClick={toggleHome} navscroll={colorChange}>
                            DEVELOPERCHEF
                        </NavLogo>
                        <MobileIcon onClick={toggle}>
                            {isOpen ? <FaTimes style={{ color: 'black' }} /> : <FaBarsIcon style={{ color: 'black' }} />}
                        </MobileIcon>
                        <NavMenu isOpen={isOpen}>
                            <NavItem isOpen={isOpen}>
                                <NavLinks to="profile"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80
                                    }
                                    navscroll={colorChange}
                                >
                                    <span>ABOUT</span>
                                </NavLinks>
                            </NavItem>
                            <NavItem isOpen={isOpen}>
                                <NavLinks to="skills"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80
                                    }
                                    navscroll={colorChange}
                                >
                                    <span>SKILLS</span>
                                </NavLinks>


                            </NavItem>
                            <NavItem isOpen={isOpen}>
                                <NavLinks to="portfolio"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80
                                    }
                                    navscroll={colorChange}
                                >
                                    <span>PORTFOLIO</span>
                                </NavLinks>

                            </NavItem>

                            <NavItem isOpen={isOpen}>
                                <NavLinks to="contact"
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80
                                    }
                                    navscroll={colorChange}
                                >
                                    <span>CONTACT</span>
                                </NavLinks>
                            </NavItem>
                            <NavItem isOpen={isOpen}>
                                <NavButton href={cvPdf} target='_blank' navscroll={colorChange}><span>CV</span></NavButton>

                            </NavItem>
                        </NavMenu>

                    </NavbarContainer>

                </Nav>

            </IconContext.Provider>



        </>
    )
}

export default Navbar



const Nav = styled.nav`

background:${({ scrollNav }) => (scrollNav ? '#fff' : 'transparent')};
height: 80px;
margin-top: -80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
position: sticky;
top:0;
z-index: 10;



@media screen and (max-width:960px){
    transition: 0.8s all ease;
    background: #fff;
    
    
}
`;

const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: ${({ isOpen }) => (isOpen ? '100px' : '50px')};
z-index: 10;
width: 100%;
padding: 0 24px;
max-width:1100px;


`;

const NavLogo = styled(LinkR)`
color: ${({ navscroll }) => (navscroll === 'true' ? '#222222' : '#fff')};
justify-self:flex-start;
cursor: pointer;
font-size: 1.5rem;
display:flex;
align-items: center;
margin-left: 24px;
font-weight:bold;
text-decoration: none;
z-index: 10;

@media screen and (max-width:960px){
    color: #222222;
}


`;

const MobileIcon = styled.div`

display: none;
@media screen and (max-width: 960px){
    display:block;
    position: absolute;
    top:0;
    right:0;
    transform: translate(-100%,60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #222222;
    z-index: 10;
    

}

`;
const FaBarsIcon = styled(FaBars)`
color:#000;
`;


const NavMenu = styled.ul`
display: flex;
align-items: center;
list-style: none;
text-align:center;
margin-right: -22px;


@media screen and (max-width: 960px){
    display: none;

}
`;

const NavItem = styled.li`
height:80px;



`;

const NavLinks = styled(LinkS)`
align-items: center;
display: flex;
flex-flow: row nowrap;
height: 100%;
justify-content: flex-end;

padding: 0px;
position: relative;
margin-right:auto;
margin-left:25px;

&.active {
    span{
border-bottom: 2px solid #000;}
}


    
    span{
        color: ${({ navscroll }) => (navscroll === 'true' ? '#222222' : '#fff')};
        font-size: 13px;
        letter-spacing: 1.42px;
        line-height: 1.08;
        padding:2px 0px;
        white-space:nowrap;
        position: relative;
        cursor: pointer;
        
 
        @media screen and (max-width: 960px){
            color:#000;
            
        }
    
&:before{
    background-color: #000;
    border-radius:0px 0px 4px 4px;
    bottom: -2px;
    content:'';
    height:2px;
    left:0px;
    opacity: 0;
    position: absolute;
    right:0px;
    transform-origin: left center;
    transform: scaleX(0);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    visibility: hidden;
    width: auto;
}

}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}



&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}


`;

const NavButton = styled.a`
align-items: center;
display: flex;
flex-flow: row nowrap;
height: 100%;
justify-content: flex-end;

padding: 0px;
position: relative;
margin-right:auto;
margin-left:25px;
text-decoration: none;
&.active {
    span{
border-bottom: 2px solid #000;}
}


    
    span{
        color: ${({ navscroll }) => (navscroll === 'true' ? '#222222' : '#fff')};
        font-size: 13px;
        letter-spacing: 1.42px;
        line-height: 1.08;
        padding:2px 0px;
        white-space:nowrap;
        position: relative;
        cursor: pointer;
        
 
        @media screen and (max-width: 960px){
            color:#000;
            
        }
    
&:before{
    background-color: #000;
    border-radius:0px 0px 4px 4px;
    bottom: -2px;
    content:'';
    height:2px;
    left:0px;
    opacity: 0;
    position: absolute;
    right:0px;
    transform-origin: left center;
    transform: scaleX(0);
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    visibility: hidden;
    width: auto;
}

}
&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}



&:hover {
    span:before {
        transform:scaleX(1);
        visibility:visible;
        opacity:1 !important;
    
    
    }
}


`;



