import React from 'react'
import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { Bounce } from 'react-awesome-reveal'
import Background from '../../images/background.jpg';
import ProfileImg from '../../images/handsome2.jpg';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';


const Main = () => {


    return (
        <>
            <MainDiv>

                <ImgWrapper>
                    <ImgHolder />
                </ImgWrapper>
                <IntroWrapper>
                    <Bounce direction="down" triggerOnce>
                        <IntroHeader>
                            <h1>Hello, I`am Gyula</h1>
                        </IntroHeader>
                    </Bounce>
                    <IntroParagraph>
                        <Bounce direction="down" triggerOnce>
                            <h3>Junior Software Developer</h3>
                        </Bounce>
                        <Bounce direction="up" triggerOnce>
                            <MouseHolder>

                                <ScrollDowns to='profile'

                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    exact='true'
                                    offset={-80
                                    }>
                                    <Mousey>

                                        <Scroller>

                                        </Scroller>
                                    </Mousey>
                                </ScrollDowns>
                            </MouseHolder>
                        </Bounce>
                        <ContactHolder>
                            <Bounce direction="left" triggerOnce>
                                <EmailHolder><EmailIcon /><a href='mailto:info@developerchef.com'>info@developerchef.com</a></EmailHolder>
                            </Bounce>
                            <Bounce direction="right" triggerOnce>
                                <LocationHolder><MapMarker /><h2>London,UK</h2></LocationHolder>
                            </Bounce>
                        </ContactHolder>

                    </IntroParagraph>
                </IntroWrapper>

            </MainDiv>
        </>
    )
}


export default Main


const MainDiv = styled.div`
width: 100vw;
height: 1000px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-image:linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url(${Background});
background-size: cover;
object-fit: cover;
overflow: hidden;
text-align: center;

`;

const ImgWrapper = styled.div`
min-width:500px;
height:60%;
display: flex;
justify-content: center;
align-items: center;
margin-top:50px;

@media screen and (max-width: 768px){
    min-width: 200px;
    width:70vw;
    margin-top:0;
    height:30%;
}
`;
const ImgHolder = styled.div`
width: 250px;
height: 250px;
background-image:linear-gradient(rgba(0, 0, 0, 0.227),rgba(0, 0, 0, 0.2)) , url(${ProfileImg});
object-fit: cover;
background-size: cover;
border-radius:50%;
box-shadow:2px 2px black;

@media screen and (max-width: 768px){
    width: 150px;
    height: 150px;
}

`;
const IntroWrapper = styled.div`
width: 90vw;
height: 50%;
display: flex;
flex-direction: column;


@media screen and (max-width: 480px){
    overflow: hidden;
    max-width: 80vw;
}
`;

const IntroHeader = styled.div`
width: 100%;
height: 50px;
display: flex;
justify-content: center;

h1{
    color: white;
    font-size: 50px;

    @media screen and (max-width: 768px){
        font-size: 40px;
    }
}
`;
const IntroParagraph = styled.div`
width: 100%;
height:30px;

h3{
    margin-top:30px;
    color: #fff;
    font-size: 30px;
    font-weight: 100;

    animation:flashing 4s ease-in-out infinite;

    @keyframes flashing {
        0%{
            opacity: 0%;
        }
        100%{
            opacity:100%
        }
    }
}
`;
const MouseHolder = styled.div`
width:100%;
height:100px;
display: flex;
align-items: center;
justify-content: center;

`;

const ScrollDowns = styled(LinkS)`
 position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  
  width :34px;
  height: 55px;

`;

const Mousey = styled.div`
width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
cursor: pointer;

a{
    width:30px;
    height:100%;
    position:absolute;
    right:1px;
    bottom:0.5px;
}
`;

const Scroller = styled.div`

width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(.15,.41,.69,.94);
  animation-iteration-count: infinite;

@keyframes scroll {
  0% { opacity: 0; }
  10% { transform: translateY(0); opacity: 1; }
  100% { transform: translateY(15px); opacity: 0;}
}

`;

const ContactHolder = styled.div`
width:100%;
height:100px;
display:grid;
grid-template-columns: 40% 40%;
position: relative;
align-items: baseline;
justify-content: space-between;


@media screen and (max-width:768px){
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:20px;
    height:150px;
}
`;

const EmailHolder = styled.div`
display: flex;
flex-direction: column;
align-items: center;
a{
text-decoration: none;
color: #fff;
font-size: 18px;
}

`;

const LocationHolder = styled.div`
display:flex;
flex-direction: column;
align-items: center;

h2{
    color: #fff;
    font-size: 18px;
}
`;

const MapMarker = styled(FaMapMarkerAlt)`
color: #fff;
font-size: 24px;
margin-bottom: 5px;

`;

const EmailIcon = styled(AiOutlineMail)`
color: #fff;
font-size: 24px;
margin-bottom: 5px;
`;