import React, { useState } from 'react';
import styled from 'styled-components';
import { Bounce } from 'react-awesome-reveal'
import PortImg1 from '../../images/seventh.jpg';
import PortImg2 from '../../images/action.jpg';
import PortImg3 from '../../images/lscapp.png';
import PortImg4 from '../../images/disney-clone.jpg';
import PortImg5 from '../../images/crwn-clothing.jpg';
import PortImg6 from '../../images/irisHair.jpg';



const Portfolio = () => {


    const [isShown1, setIsShown1] = useState(false);
    const [secondShown, setSecondShown] = useState(false);
    const [thirdShown, setThirdShown] = useState(false);
    const [fourthShown, setFourthShown] = useState(false);
    const [fifthShown, setFifthShown] = useState(false);
    const [sixthShown, setSixthShown] = useState(false);

    return (
        <>
            <MainProjectDiv id='portfolio'>
                <TopHeaderWrapper>
                    <Bounce direction="down" triggerOnce>
                        <h1>Portfolio</h1>
                        <span>A few of my projects.</span>
                    </Bounce>
                </TopHeaderWrapper>
                <Wrapper>
                    <Bounce direction="left" triggerOnce>
                        <Container onMouseEnter={() => setIsShown1(true)}
                            onMouseLeave={() => setIsShown1(false)}>
                            <ItemsWrapper>

                                <ProjectItems style={{ backgroundImage: `url(${PortImg1})` }} ><ShaderBox href='https://seventh-heaven.vercel.app/' target='blank'></ShaderBox><HeaderHolder>

                                </HeaderHolder></ProjectItems>
                                <h2>Seventh Heaven</h2>
                            </ItemsWrapper>

                            <NameWrapper isShown={isShown1}>
                                <h2>Seventh Heaven</h2>
                                <p>ReactJs</p>
                            </NameWrapper>
                        </Container>
                    </Bounce>

                    <Bounce direction="down" triggerOnce>
                        <Container onMouseEnter={() => setSecondShown(true)}
                            onMouseLeave={() => setSecondShown(false)}>
                            <ItemsWrapper><ProjectItems style={{ backgroundImage: `url(${PortImg2})` }} ><ShaderBox href='https://action-olive.vercel.app/' target='blank'></ShaderBox><HeaderHolder>

                            </HeaderHolder></ProjectItems><h2>Theatre Club</h2></ItemsWrapper>
                            <NameWrapper isShown={secondShown}>
                                <h2>Theatre Club</h2>
                                <p>ReactJs</p>
                            </NameWrapper>
                        </Container>

                    </Bounce>

                    <Bounce direction="right" triggerOnce>
                        <Container onMouseEnter={() => setThirdShown(true)}
                            onMouseLeave={() => setThirdShown(false)} >
                            <ItemsWrapper><ProjectItems style={{ backgroundImage: `url(${PortImg3})` }} ><ShaderBox href='https://fasting-kohl.vercel.app/index.html' target='blank'>

                            </ShaderBox><HeaderHolder>

                                </HeaderHolder></ProjectItems><h2>LSC APP</h2></ItemsWrapper>
                            <NameWrapper isShown={thirdShown}>
                                <h2>LSC APP</h2>
                                <p>Javascript</p>
                            </NameWrapper>

                        </Container>

                    </Bounce>

                    <Bounce direction="left" triggerOnce>
                        <Container onMouseEnter={() => setFourthShown(true)}
                            onMouseLeave={() => setFourthShown(false)}>
                            <ItemsWrapper><ProjectItems style={{ backgroundImage: `url(${PortImg4})` }} ><ShaderBox href='https://disney-mu.vercel.app/' target='blank'></ShaderBox><HeaderHolder>

                            </HeaderHolder></ProjectItems><h2>Disney+ Clone</h2></ItemsWrapper>

                            <NameWrapper isShown={fourthShown}>
                                <h2>Disney+ Clone</h2>
                                <p>ReactJs/Firebase</p>
                            </NameWrapper>
                        </Container>

                    </Bounce>


                    <Bounce direction="up" triggerOnce>
                        <Container onMouseEnter={() => setFifthShown(true)}
                            onMouseLeave={() => setFifthShown(false)}>
                            <ItemsWrapper><ProjectItems style={{ backgroundImage: `url(${PortImg5})` }} ><ShaderBox href='https://crwn-live-shuyin.herokuapp.com/' target='blank'></ShaderBox><HeaderHolder>

                            </HeaderHolder></ProjectItems><h2>CRWN Clothing</h2></ItemsWrapper>

                            <NameWrapper isShown={fifthShown}>
                                <h2>CRWN Clothing</h2>
                                <p>ReactJs/Firebase</p>
                            </NameWrapper>
                        </Container>

                    </Bounce>

                    <Bounce direction="right" triggerOnce>
                        <Container onMouseEnter={() => setSixthShown(true)}
                            onMouseLeave={() => setSixthShown(false)}>
                            <ItemsWrapper><ProjectItems style={{ backgroundImage: `url(${PortImg6})` }} ><ShaderBox href='https://irishair.vercel.app/' target='blank'></ShaderBox><HeaderHolder>

                            </HeaderHolder></ProjectItems><h2>Iris Hair Salon</h2></ItemsWrapper>

                            <NameWrapper isShown={sixthShown}>
                                <h2>Iris Hair Salon</h2>
                                <p>ReactJs</p>
                            </NameWrapper>
                        </Container>
                    </Bounce>
                    <Bounce direction="left" triggerOnce>
                        <IFrame src="https://replit.com/@shuyin21/Hangman-finished?embed=true"></IFrame>

                    </Bounce>
                    <iframe width="400" height="315" src="https://www.youtube.com/embed/WBcLy-2Sm00" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <Bounce direction="right" triggerOnce>
                        <IFrame src="https://replit.com/@shuyin21/Travelbuddy?embed=true"></IFrame>

                    </Bounce>




                </Wrapper>
            </MainProjectDiv>
        </>
    )
}

export default Portfolio



const MainProjectDiv = styled.div`
width: 100vw;
min-height:800px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

`;
const TopHeaderWrapper = styled.div`
display: flex;
flex-direction: column;
width: 70%;
height:100px;


@media screen and (max-width:1300px){
    width: 90%;
}
@media screen and (max-width:768px){
    align-items: center;
}

span{
    color:#ADADA3;
}
`;

const Wrapper = styled.div`
width: 70%;
margin: 2% 10%;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;



@media screen and (max-width:1300px){
    width: 90%;
}




@media screen and (max-width:850px){
width: 100%;
    flex-direction: column;
    
    
}

`;
const ItemsWrapper = styled.div`
width: 360px;
height: 200px;
margin: 10px;
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;

overflow: hidden;
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);



@media screen and (max-width:850px){
    width: 90vw;
    height: 100%;
}


h2{
    display: none;
}

@media screen and (max-width:500px){
    h2{
    display: flex;
background-color: #000;
color: #fff;
width:100%;
height:70px;
align-items: center;
justify-content: center;
border:1px solid #EF4836;
}
}


`;
const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;


const NameWrapper = styled.div`
width:310px;
height:100px;
background-color: transparent;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
visibility: ${({ isShown }) => (isShown ? '1' : 'hidden')};

@media screen and (max-width:450px){
    display: none;
}



h2{
    color: gray;
    text-shadow:1px 0.5px black;
}




`;



const ProjectItems = styled.div`

width: 100%;
height: 100%;
position:relative;


background-size: cover;
object-fit: cover;


&:hover{
transform: scale(1.2);
transition-duration: 0.5s;




   
}


@media screen and (max-width:850px){
width: 100%;
height: 200px;


}
`;
const ShaderBox = styled.a`
width: 95vw;
  height: 400px;
  background: rgba(0,0,0,0.2);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;



`;

const HeaderHolder = styled.div`
display:flex;
justify-content: center;
align-items: center;
width: 310px;
height: 100%;

margin:0px 0 50px;


h2 {
  
    color: white;
    background-color: #000;
  font-size: 28px;
  margin: 0;
  transform: translate(-50%,-50%);
  text-align: center;
  
  position: absolute;
  top: 50%;
  left: 50%;
  text-shadow: 1px 1px #000;
 
}
`;

const IFrame = styled.iframe`

width:370px ;
height:400px;
margin:10px;
`;