import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { Bounce } from 'react-awesome-reveal'
import { FaMapMarkerAlt } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';


const Contact = () => {

    const [click, setClick] = useState(true);


    function buttonClicked() {
        setClick(true);
        console.log('clicked' + click);
    }


    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_fxbc1u6', 'template_6owcgks', form.current, 'user_tHo73ui8EQ4EsfNlAqvvx').then((result) => {
            console.log(result.text);
            setClick(false);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };


    return (
        <>
            <ContactDiv id='contact'>

                <ContactHeader>
                    <Bounce direction="left" triggerOnce>
                        <h1>Contact Me</h1>
                        <span>Drop me an email</span>
                    </Bounce>
                </ContactHeader>

                <ContactWrapper>
                    <LeftDiv>
                        <Bounce direction="up" triggerOnce>
                            <EmailHolder>
                                <EmailIcon />
                                <a href="mailto:info@developerchef.com">info@developerchef.com</a></EmailHolder>
                            <LocationHolder><MapMarker /><h2>London,UK</h2></LocationHolder>
                        </Bounce>
                    </LeftDiv>
                    <RightDiv>

                        <FormDiv ref={form} onSubmit={sendEmail}>
                            <Bounce direction="right" triggerOnce>
                                <FormInput required type='text' name='name' placeholder='Name' />
                                <FormInput required type='email' name='email' placeholder='Email' />
                                <FormInput required type='text' autoComplete='off' name='subject' placeholder='Subject' />
                                <FormTextarea required name='message' placeholder='Your message' />

                                <FormButton type='submit' value='Send' />
                            </Bounce>
                        </FormDiv>


                    </RightDiv>
                </ContactWrapper>

                <EmailBox click={click}>
                    <h1>Email sent successfully</h1>
                    <button onClick={buttonClicked} >OK</button>
                </EmailBox>

            </ContactDiv>
        </>
    )
}

export default Contact


const ContactDiv = styled.div`
height:90vh;
width:100vw;
display:flex;
flex-direction: column;
justify-content:center;
align-items:center;
background-color:#18191a;
padding:5% 15%;

`;

const ContactHeader = styled.div`
width: 100%;
height:15%;
display:flex;
flex-direction: column;
align-items:flex-start;
justify-content: center;
color:white;



`;

const ContactWrapper = styled.div`
width: 100%;
height:90%;
display:flex;
align-items:center;
justify-content: space-between;
padding-bottom: 10%;

@media screen and (max-width:1000px){
    flex-direction: column;
    min-height:80%;
}

`;

const LeftDiv = styled.div`
width:40%;
height:100%;
display:flex;
flex-direction: column;
justify-content: flex-start;
align-items:flex-start;

@media screen and ( min-width:1000px) and (max-width:1300px){
    width: 30%;
}
@media screen and (max-width:1000px){
    width: 100%;
}
`;


const EmailHolder = styled.div`
display: flex;
justify-content: center;
align-items: center;
margin: 10px 0;
a{
text-decoration: none;
color: #fff;
font-size: 15px;
}

`;

const LocationHolder = styled.div`
display:flex;
justify-content: center;
align-items: center;

h2{
    color: #fff;
    font-size: 15px;
}
`;

const MapMarker = styled(FaMapMarkerAlt)`

color: #fff;
font-size: 15px;
margin-right: 5px;


`;

const EmailIcon = styled(AiOutlineMail)`
color: #fff;
font-size: 15px;
margin-right: 5px;
`;




const RightDiv = styled.div`
width:50%;
height:100%;
display:flex;
flex-direction: column;
justify-content: flex-start;
align-items:center;

@media screen and ( min-width:1000px) and (max-width:1300px){
    width: 70%;
}

@media screen and (max-width:1000px){
    width: 100%;
}
`;

const FormDiv = styled.form`
display:flex;
flex-direction: column;
width:100%;
height:100%;
align-items: space-between;
justify-content: space-between;

@media screen and (max-width:1000px){
    height:400px;
}
@media screen and (max-width:768px){
    
    overflow:hidden;
}

`;
const FormInput = styled.input`
width:100%;
height:3rem;
border-radius: 10px;
padding-left:20px;
`;

const FormTextarea = styled.textarea`
width: 100%;
height:7rem;
border-radius: 10px;
padding: 5px 0 0 20px;


`;
const FormButton = styled.input`
width:30%;
height:3rem;
color: #fff;
background-color:transparent;
border:2px solid #EF4836;
cursor:pointer;
font-size: 18px;

&:hover{
    background-color:#EF4836 ;
}
`;


const EmailBox = styled.div`
position:absolute;
display:${({ click }) => (click === true ? 'none' : 'flex')};
flex-direction: column;
background-color:#000;
color:#fff;
height:200px;
width:300px;
border:2px solid #EF4836;
border-radius: 10px;
align-items: center;
justify-content: center;
text-align: center;
font-size: 15px;


button{
    margin:10px;
    padding: 5px 20px;
    cursor:pointer;
    border:2px solid #EF4836;
    background-color:black;
    color:white;


    &:hover{
        background-color:#EF4836 ;
    }
}
`;

