export const ProfileInfo = {
    info: 'Focused and talented Junior Software Developer with expertise in web development and design. Rich set of skills with various Web Applications frameworks, tools and libraries. Strong interest in latest technologies, enjoy working with various programming languages.I am passionate about delivering high quality web solutions, adopting new technologies and methodologies. Have experience with backend languages like Python or ExpressJs,libraries like NodeJs and i used SQL and NoSQL Databases.'
}


export const BackEndSkills = {
    color1: '#353535',
    color2: '#353535',
    color3: '#353535',
    color4: '#ADADA3',
    color5: '#ADADA3',
    skillName: 'Firebase / MySql'
}

export const HtmlSkills = {
    color1: '#353535',
    color2: '#353535',
    color3: '#353535',
    color4: '#353535',
    color5: '#353535',
    skillName: 'HTML5'
}
export const CSS3Skills = {
    color1: '#353535',
    color2: '#353535',
    color3: '#353535',
    color4: '#353535',
    color5: '#ADADA3',
    skillName: 'CSS3 / SASS'
}
export const JavascriptSkills = {
    color1: '#353535',
    color2: '#353535',
    color3: '#353535',
    color4: '#353535',
    color5: '#ADADA3',
    skillName: 'Javascript / ReactJs'
}

export const pythonSkills = {
    color1: '#353535',
    color2: '#353535',
    color3: '#ADADA3',
    color4: '#ADADA3',
    color5: '#ADADA3',
    skillName: 'Python'
}