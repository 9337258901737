import React from 'react';
import { Bounce } from 'react-awesome-reveal';

import styled from 'styled-components';

import MacImg from '../../images/mac-img2.jpg';
import { ProfileInfo, BackEndSkills, HtmlSkills, CSS3Skills, JavascriptSkills, pythonSkills } from './ProfileInfo';
import { FaGithub } from 'react-icons/fa';
import { FaLinkedin } from 'react-icons/fa';
import SkillsList from './SkillsList';


const Profile = () => {





    return (
        <>
            <ProfileDiv id='profile'>
                <ProfileWrapper>
                    <ProfileHeader>
                        <Bounce direction="down" triggerOnce>
                            <h1>Gyula</h1>
                            <span>Junior Software Developer</span>
                        </Bounce>
                    </ProfileHeader>
                    <InfoWrapper>
                        <Bounce direction="left" triggerOnce>
                            <ProfileImageWrapper>





                            </ProfileImageWrapper>
                        </Bounce>

                        <Bounce direction="up" triggerOnce>
                            <SkillsWrapper>
                                <h1>SKILLS</h1>

                                <SkillsList {...HtmlSkills} />
                                <SkillsList {...CSS3Skills} />
                                <SkillsList {...JavascriptSkills} />
                                <SkillsList {...BackEndSkills} />
                                <SkillsList {...pythonSkills} />

                                <ProfLinkWrapper>
                                    <a href='https://github.com/shuyin21' target='_blank' rel='noreferrer' style={{ color: '#000' }} ><GithubI /></a>
                                    <a href='https://www.linkedin.com/in/gyula-nagy-90b0241a3/' target='blank' rel='noreferrer' style={{ color: '#0e76a8' }}><LinkedinI /></a>

                                </ProfLinkWrapper>

                            </SkillsWrapper>
                        </Bounce>
                        <Bounce direction="right" triggerOnce>
                            <InfoHolder>
                                <h2>PROFESSIONAL PROFILE</h2>
                                <p>{ProfileInfo.info}</p>
                            </InfoHolder>
                        </Bounce>
                    </InfoWrapper>
                </ProfileWrapper>
            </ProfileDiv>
        </>
    )
}

export default Profile



const ProfileDiv = styled.div`
width: 100vw;
max-height: 80vh;
background-color: #fff;
display: flex;
align-items: center;
justify-content: center;

@media screen and (max-width:1300px){
    max-height: 100%;
}
@media screen and (max-width:768px){
  
   height: 100%;
   margin-top: 70px;
   overflow: hidden;
}

`;

const ProfileWrapper = styled.div`
display: flex;
flex-direction: column;
margin: 10%;
width: 70%;
height: 70%;

@media screen and (max-width:1300px){
    margin:2%;
    width:90%;
}
@media screen and (max-width:1000px){
    margin:5%;
    width:90%;
}

@media screen and (max-width:768px){
    align-items: center;
    justify-content: center;
}
`;
const ProfileHeader = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
height:70px;
justify-content: space-between;
margin-bottom: 50px;


@media screen and (max-width:768px){
    align-items: center;
    justify-content: center;

    overflow: hidden;
}
h1{
    font-size: 40px;
    margin-bottom: 10px;
}
span{
    color:#ADADA3;
}
`;
const InfoWrapper = styled.div`
display: grid;
grid-template-columns: 30% 30% 40%;
height:100%;


@media screen and (max-width:1000px){
    grid-template-columns: 50% 50%;
    
}
@media screen and (max-width:768px){
    display: flex;
    flex-direction: column;
    align-items: center;

    

}
`;
const ProfileImageWrapper = styled.div`

width:100%;
height:100%;
background-image: url(${MacImg});
object-fit: cover;
background-size: cover;

@media screen and (max-width:768px){
    display:none;
}
`;



const SkillsWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
margin:0 20px;

h1{
    margin-bottom: 20px;
    font-size: 24px;
    font-weight:100;


    @media screen and (max-width:768px){
    font-size: 22px;
    
}
}


    @media screen and (max-width:768px){
    align-items: center;
    justify-content: center;
    width:90vw;
    margin:50px 0 0;
}
`;
const ProfLinkWrapper = styled.div`
display: flex;
width: 100%;
margin-top: 50px;
justify-content: flex-start;

a{
    text-decoration: none;
    
    background-color: none;
}

@media screen and (max-width:768px){
    justify-content: center;
}

`;

const GithubI = styled(FaGithub)`
font-size: 40px;
cursor: pointer;
margin: 0 10px;
`;

const LinkedinI = styled(FaLinkedin)`
font-size: 40px;
cursor: pointer;
margin:0 10px;
`;

const InfoHolder = styled.div`

width:80%;

@media screen and (max-width:1000px){
    width:90vw;
    display: flex;
    flex-direction: column;
    margin: 50px 0;
    margin-bottom: 50px;
   
}

@media screen and (max-width:768px){
    align-items: center;
    justify-content: center;
}

h2{
    margin-bottom: 10px;
    font-weight: 100;
}

p{
    color:#ADADA3;
    line-height: 1.3;
}
`;