import React from 'react';
import styled from 'styled-components';

const SkillsList = ({ color1, color2, color3, color4, color5, skillName }) => {

    // console.log(color1);
    return (
        <>
            <SkillMainDiv>
                <h3>{skillName}</h3>
                <SkillsHolder>

                    <SkillDivOne color={color1} />
                    <SkillDivTwo color={color2} />
                    <SkillDivThree color={color3} />
                    <SkillDivFour color={color4} />
                    <SkillDivFive color={color5} />
                </SkillsHolder>
            </SkillMainDiv>
        </>
    )
}

export default SkillsList

const SkillMainDiv = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height:50px;

@media screen and (max-width:768px){
    align-items: center;
    justify-content: center;
}

h3{
    font-weight: 100;
    font-size: 13px;
}

`;

const SkillsHolder = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding:0 4%;
width:100%;
height:100%;
`;

const SkillDivOne = styled.div`
width:19%;
height:4px;
background-color:${props => props.color} ;
`;
const SkillDivTwo = styled.div`
width:19%;
height:4px;
background-color:${props => props.color} ;
`;
const SkillDivThree = styled.div`
width:19%;
height:4px;
background-color:${props => props.color} ;
`;
const SkillDivFour = styled.div`
width:19%;
height:4px;
background-color:${props => props.color} ;
`;
const SkillDivFive = styled.div`
width:19%;
height:4px;
background-color:${props => props.color} ;
`;