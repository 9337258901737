import React, { useState } from 'react';
import styled from 'styled-components';

import Contact from '../Contact/Contact';
import Footer from '../Footer/Footer';
import Main from '../Main/Main';

import Navbar from '../Navbar/Navbar';
import Portfolio from '../Portfolio/Portfolio';
import Profile from '../Profile/Profile';
import Sidebar from '../Sidebar/Sidebar';
import Skills from '../Skills/Skills';


const Home = () => {




    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);

    }



    return (
        <>
            <PageWrapper>
                <Sidebar isOpen={isOpen} toggle={toggle} />
                <Navbar toggle={toggle} isOpen={isOpen} />
                <Main />
                <Profile />
                <Skills />
                <Portfolio />
                <Contact />
                <Footer />
            </PageWrapper>
        </>
    )
}

export default Home


const PageWrapper = styled.div`
box-sizing: border-box;
    margin:0;
    padding:0;
/* overflow: hidden; */

`;